// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
Rails.start();

import Turbolinks from 'turbolinks';
Turbolinks.start();

// Import bootstrap and required libraries
import './bootstrap';

// Import base components
import './components/base';

// Fonts
import './fonts';

import 'chartkick';
import 'chartkick/chart.js';
