function init() {
  $('.selectize-multiple').selectize({
    delimiter: ",",
    persist: false,
    create: function (input) {
      return {
        value: input,
        text: input,
      };
    },
  });

  $('.selectize-basic').selectize({
    create: true
  });
};

document.addEventListener("turbolinks:load", init);
