function init() {
  $('.table-selectable #table-check-all').on('change', (e) => {
    const checked = e.target.checked;
    const $table = $(e.target).closest('table');
    $table.find('input[type=checkbox]').prop('checked', checked);
  });
};

window.addEventListener("load", init);
document.addEventListener("turbolinks:load", init);

