function init() {
  const timeout = setTimeout(() => {
    document.querySelectorAll('.alert[data-auto-hide="true"]').forEach((x) => {
      x.classList.remove('show');

      setTimeout(() => x.remove(), 150);
    });
  }, 4000);
};

window.addEventListener("load", init);
document.addEventListener("turbolinks:load", init);
